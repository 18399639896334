import React from "react";
// Customizable Area Start
import {
  Box,
  InputAdornment,
  MenuItem,
  Typography,
  Select,
  Pagination,
  PaginationItem
} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { 
           logo, timer, mapIcon, calIcon, estimate, userIcon,  viewLocation, viewcircle,
           techsIcon, friendlyIcon,  indoorImage, teenImage, view
          }
            from "./assets"
          import { styled } from "@mui/styles";

// Customizable Area End
const MainWrapper = styled(Box)({
    maxWidth: "1272px",
    width: "100%",
    "@media (max-width: 1300px)": {
        width: "80%"
    },
    "& .typotext": {
        fontFamily: "Manrope",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "28.8px",
        color: "#25324B",
        paddingTop: "50px",
        padding:"24px"
    },
    "& .spanText": {
        fontFamily: "Manrope",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "28.8px",
        color: "#25324B"
    },
    "& .detailBox": {
        width: "100%",
        display:"flex",
        gap:"123px",
        marginTop:"24px",
        alignItems: "center",
        borderRadius: "8px",
        backgroundColor: "#F1F5F9",
        border: "1px solid #1414140D",
        boxShadow: "0px 1px 2px 0px #00000008",
        "@media (max-width: 768px)": {
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        }
    },
    "& .nameText": {
        fontFamily: "DM Sans",
        fontSize: "24px",
        fontWeight: 500,
        lineHeight: "36px",
        color: "#141414",
        paddingBottom: "12px"
    },
    "& .locationText": {
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#141414B2"
    },
    "& .commonBlackText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#141414"
    },
    "& .empowerText": {
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontStyle: "italic",
        fontWeight: 500,
        lineHeight: "24px",
        color:"#141414B2",
        paddingTop: "12px",
        paddinBottom: "12px"
    },
    "& .chipsBox": {
        height:"30px",
        gap:"8px",
        paddingLeft:"8px",
        paddingRight:"8px",
        display:"flex",
        alignItems: "center",
        backgroundColor:"#FFFFFF",
        borderRadius: "69.77px"
    },
    "& .chipsText": {
        fontFamily: "Manrope",
        fontSize: "12.21px",
        fontWeight: 700,
        lineHeight: "19.54px",
        color: "#393939"
    },
    "& .teenImage": {
        height:"284px",
        width:"219px",
        paddingLeft:"107px",
        paddingTop: "79px",
        paddingBottom: "79px",
        "@media (max-width: 768px)": {
            paddingLeft: "0px",
            paddingBottom: "0px"
        }
    },
    "& .responsiveContainer": {
        width:"100%",
        "@media (max-width: 768px)": {
            textAlign: "center"
        }
    },
    "& .respoDiv": {
        display:"flex",
        gap:"11px",
        alignItems:"center",
        flexWrap: "wrap",
        "@media (max-width: 768px)": {
           justifyContent: "center" 
        }
    },
    "& .respoText": {
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#141414B2",
        width:"60%",
        paddingBottom: "16px",
        "@media (max-width: 768px)": {
           width: "100%" 
        }
    },
  })

  const MainNav = styled(Box)({
    width: "100%",
    height: "74px",
    maxWidth: "1440px",
    border: "1px solid #D6DDEB",
    paddingLeft: "84px",
    paddingRight: "84px",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 768px)": {
      paddingLeft: "10px",
      width: "94%",
    },
    "& .imageview": {
        display: "none",
        "@media (max-width: 768px)": {
          display: "block",
        },
    }
  })

  const NavWrap = styled(Box)({
    gap:"40px",
    borderLeft: "1px solid #D6DDEB",
    height: "48px",
    display: "flex",
    alignItems: "center",
    paddingRight: "84px",
    "@media (max-width: 768px)": {
      display: "none"
    },
  })

  const BoxWrapper = styled(Box)({
    display: "flex",
    gap: "16px",
    flexWrap: "wrap",
    width: "100%",
    borderRadius: "13px",
    alignItems: "center",
    backgroundColor: "#F1F5F9",
    paddingTop: "29px",
    paddingBottom:"29px",
    "@media (max-width: 768px)": {
      justifyContent: "center"
    },
      "& .Innerbox": {
          paddingLeft: "27px",
          display: "flex",
          gap: "16px",
          flexWrap: "wrap",
          "@media (max-width: 768px)": {
              justifyContent: "center",
              padding: "0px"
          }
      },
    "& .selectBox": {
    fontFamily: "Manrope",
    color: "#161C2D",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "-0.11px",
    borderRadius: "10px"
    }
  })

const CustomPagination = styled(Pagination)({
    '& .MuiPaginationItem-page': {
        fontSize: '16px',
        color: '#515B6F',
        fontWeight: 600,
        fontFamily: "Roboto",
        "@media (max-width: 500px)": {
            fontSize: "10.5px !important",
            minWidth: "5px !important"
        },
        '&.Mui-selected': {
            backgroundColor: '#5E70DD',
            color: '#FFFFFF',
            borderRadius: '8px',
            padding: "10px 12px",
            fontSize: '16px',
            fontWeight: 600,
            fontFamily: "Roboto",
            minWidth: "46px !important",
            minHeight: "46px !important",
            "@media (max-width: 500px)": {
                padding: "6.5px 8px !important",
                borderRadius: "5.27px",
                minWidth: "30px !important",
                minHeight: "30px !important"
            },
        },
    },
    '& .MuiPaginationItem-root': {
        minWidth: "32px !important",
        "@media (max-width: 500px)": {
            minWidth: "5px !important"
        },
    },
    '& .MuiPaginationItem-previous, .MuiPaginationItem-next': {
        color: '#25324B',
    },
    '& .MuiSvgIcon-root': {
        width: "15.8px",
        height: "15.8px"
    }
});

const Footer = styled(Box)({
    maxWidth: "1440px",
    width: "100%",
    padding: "24px 80px 24px 80px",
    backgroundColor: "#5E70DD",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 1300px)": {
        width: "90%"
    },
    "& .footerText": {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 400,
        color: "#FFFFFF"
    }
})

// Customizable Area End

import AllCandidateResultController, { Props } from "./AllCandidateResultController";

export default class AllCandidateResult extends AllCandidateResultController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    renderPagintaion = () => {
        return <Box style={{ paddingTop: "56", paddingBottom: "61px" }}>
            <CustomPagination
                data-test-id="pageChangeTestBtn"
                count={33}
                page={this.state.pageState}
                onChange={this.handleChangePage}
                shape="rounded"
                renderItem={(item) => (
                    <PaginationItem
                        components={{
                            previous: ArrowBackIosIcon,
                            next: ArrowForwardIosIcon,
                        }}
                        {...item}
                    />
                )}
            /></Box>
    }
    // Customizable Area End
  render() {
    // Customizable Area Start
  let services = [
    {"serviceName": "Tech Help", img: techsIcon.default},
    {"serviceName": "Indoor Help", img: indoorImage.default},
    {"serviceName": "Friendly Visit", img: friendlyIcon.default},
  ]
  let { recommond } = this.state
    return (

        <Box style={{ overflowX: "hidden" }}>
            <MainNav>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "95%" }}>
                    <img src={logo.default} style={{ width: "195px", height: "21px" }} />
                    <img className="imageview" src={userIcon.default} />
                    <NavWrap>
                        <Box></Box>
                        <Typography style={{
                            fontFamily: "Manrope", fontSize: "16px", fontWeight: 700, lineHeight: "25.6px",
                            textAlign: "center", color: "#39393F"
                        }} onClick={this.handleNavigation.bind(this, "UserProfileBasicBlock")}
                            data-test-id="UserProfileBasicBlockId">{this.state.userName?.fullName}</Typography>
                    </NavWrap>
                </Box>
            </MainNav>
            <Box style={{ display:"flex", flexDirection:"column", justifyContent: "center", alignItems: "center"}}>
            <MainWrapper>
                <Typography className="typotext">Bookings Requests for <span className="spanText">Jenny Wilson</span></Typography>
                    <BoxWrapper>
                        <Box className="Innerbox">
                            <Select
                                defaultValue={1}
                                className="selectBox"
                                sx={{
                                    width: "158px",
                                    height: "50px"
                                    }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <img src={view.default} style={{ color: "" }} />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem key={1} value={1} >Tech Help</MenuItem>
                            </Select>
                            <Select
                                defaultValue={1}
                                className="selectBox"
                                sx={{
                                    width: "180px",
                                    height: "50px",
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <img src={mapIcon.default} style={{ color: "" }} />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem key={1} value={1} >Select Location</MenuItem>
                            </Select>
                            <Select
                                defaultValue={1}
                                className="selectBox"
                                sx={{
                                    width: "208px",
                                    height: "50px",
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <img src={calIcon.default} style={{ color: "" }} />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem key={1} value={1} >12 May - 13 May</MenuItem>
                            </Select>

                            <Select
                                defaultValue={1}
                                className="selectBox"
                                sx={{
                                    width: "180px",
                                    height: "50px",
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <img src={timer.default} style={{ color: "" }} />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem key={1} value={1} >Estimated Hours</MenuItem>
                            </Select>

                            <Select
                                defaultValue={1}
                                className="selectBox"
                                sx={{
                                    width: "180px",
                                    height: "50px",
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <img src={estimate.default} style={{ color: "" }} />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem key={1} value={1} >Frequency</MenuItem>
                            </Select>
                        </Box>
                    </BoxWrapper>
                    <Box className="detailBox">
                        <img src={teenImage.default} className="teenImage"/>
                        <Box className="responsiveContainer">
                            <Typography className="nameText">Jack Tohas</Typography>
                            <Box>
                                <Box className="respoDiv">
                                    <Box style={{display:"flex", gap:"4px", width:"110px", alignItems:"center"}}>
                                    <img src={viewLocation.default} />
                                    <Typography className="locationText">New York</Typography>
                                    </Box>
                                    <img src={viewcircle.default}  style={{height:"2px", width: "2px"}}/>
                                <Box style={{display:"flex", gap:"11px", alignItems:"center"}}>
                                   <span className="commonBlackText">State:</span> <Typography className="locationText">New York</Typography>
                                   <img src={viewcircle.default} style={{height:"2px", width: "2px"}}/>
                                   <span className="commonBlackText">ZIP Code:</span> <Typography className="locationText"> 10956 $ 120/h</Typography>
                                </Box>
                                </Box>
                                <Typography className="empowerText">"Empowering tomorrow's leaders today"</Typography>
                                <Typography className="commonBlackText" style={{fontWeight: 700, paddingBottom: "12px"}}>How I can help</Typography>
                                <Typography className="respoText">Help with grocery shopping, picking up prescriptions, or running other errands. This can be a big help for seniors who have trouble getting around.</Typography>
                                <Typography className="commonBlackText" style={{fontWeight: 700, paddingBottom: "12px"}}>Services I Can Provide</Typography>
                                <Box style={{display:"flex", gap: "6px", alignItems: "center"}}>
                                {
                                    services.map((item) => 
                                         <Box className="chipsBox">
                                             <img src={item.img} />
                                            <Typography className="chipsText">{item.serviceName}</Typography>
                                        </Box>
                                        )
                                }
                                </Box>
                                <Typography className="commonBlackText" style={{fontWeight: 700, paddingTop: "16px", paddingBottom: "12px"}}>Recommendations</Typography>
                                <Box style={{display:"flex", gap: "6px", alignItems: "center"}}>
                                {
                                    recommond.map((item) => 
                                         <Box className="chipsBox">
                                            <Typography className="chipsText">{item.text}</Typography>
                                        </Box>
                                        )
                                }
                                </Box>
                                
                            </Box>
                        </Box>
                    </Box>
            </MainWrapper>
            {this.renderPagintaion()}
            <Footer>
                <Typography className="footerText" style={{lineHeight: "19.6px"}}>CompanyName @ 202X. All rights reserved.</Typography>
                <Typography className="footerText">Terms & Conditions</Typography>
            </Footer>
            </Box>
        </Box>
      );
      // Customizable Area End
    }
  }
  
  // Customizable Area Start
  export const webStyle = { }
  // Customizable Area End