import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    progress: number;
    currentTab: number
    selected: boolean
    userName: any
    tabs: any[],
    bookingRequestData: any;
    profileImageUrl: string;
    adminProfileEditBtn: boolean;
    adminProfileSaveBtn: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AdminDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    APiCallId: string = "";
    getBookingsCallId: string = ";"
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            progress: 0,
            currentTab: 0,
            selected: false,
            tabs: [
                { label: "Dashboard" },
                { label: "Profile" },
                { label: "Bookings Requests" },
                { label: "Messages" },
                { label: "Settings" }
            ],
            userName: JSON.parse(String(localStorage.getItem("login"))),
            bookingRequestData: {},
            profileImageUrl: "",
            adminProfileEditBtn: true,
            adminProfileSaveBtn: false,
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
      
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
        if (apiRequestCallId === this.getBookingsCallId) {
            this.setState({bookingRequestData: responseJson})
            }
        // Customizable Area End
    }
    // Customizable Area Start
    handleSelected = () => {
        let { selected } = this.state
        this.setState({selected: !selected})
    }
    async componentDidMount(){
        this.getBookingRequestData()
    }
    getBookingRequestData = () => {
        let data = JSON.parse(String(localStorage.getItem("login")))
        const headers = {
          "Content-Type": configJSON.validationApiContentType,
          "token": data?.token
        };
          const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.getBookingsCallId = getValidationsMsg.messageId;
      
          getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.bookingrequestApiEndpoint+`${"?per_page=13&current_page=1&service_name=Pet Care"}`
          );
          getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
          );
          runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }

      handleAdminProfileEditBtn() {
        this.setState({
          adminProfileEditBtn: false,
          adminProfileSaveBtn: true
        });
      }
    
      handleAdminProfileSaveBtn() {
        this.setState({
          adminProfileEditBtn: true,
          adminProfileSaveBtn: false
        });
      }

    // Customizable Area End

}

