export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const logo = require("../assets/logo.svg");
export const bookingProfilePic = require("../assets/booking_profile_pic.svg");
export const rescheduleLogo = require("../assets/reschedule.svg");
export const confirmCheckLogo = require("../assets/confirm_check.svg");
export const userIcon = require("../assets/user_icon.svg");
export const messageBubbleIcon = require("../assets/messageBubbleIcon.svg");

export const location = require("../assets/location.svg");
export const timer = require("../assets/timer.svg");
export const receipt = require("../assets/receipt.svg");
export const vector = require("../assets/Vector.svg");
export const cardIcon = require("../assets/card.svg");
export const lockIcon = require("../assets/lock.svg");
export const flagIcon = require("../assets/flag.svg");
export const tickIcon = require("../assets/view.svg");
export const darkCard = require("../assets/darkCard.svg");
export const plusIcon = require("../assets/plusIcon.svg");
export const watchIcon = require("../assets/watchIcon.svg");
export const closeIcon = require("../assets/closeIcon.svg");
export const prevIcon = require("../assets/prev.svg");
export const nextIcon = require("../assets/next.svg");
export const bottomImage = require("../assets/bottom.png");
export const searchIcon = require("../assets/searchIcon.svg");
export const eyeIcon = require("../assets/eyeIcon.svg");
export const adamImage = require("../assets/adamImage.svg");
export const jennyImage = require("../assets/jennyImgae.svg");
