Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";

exports.putAPIEndPoint = "bx_block_profile/update_profile"
exports.getUserAPIEndpoint = "bx_block_profile/profiles"
exports.getStateAPIEndPoint = "bx_block_profile/state_list"
exports.getGradeAPIEndPoint = "bx_block_profile/grade_list"
exports.putMethod = "PUT"
exports.getMethod = "GET"
exports.patchMethod = "PATCH"
exports.formDatacontentType = "multipart/form-data"
exports.jsonContentType = "application/json"

exports.seniorUpcomingAPIEndPoint = "bx_block_request_management/upcoming_service"
exports.seniorSentRequestAPIEndPoint = "bx_block_request_management/sent"
exports.seniorPastAPIEndPoint =  "bx_block_request_management/past_requests"
exports.seniorCanceledAPIEndPoint = "bx_block_request_management/cancel_listing"

exports.bookingFilterAPIEndPoint = "/bx_block_request_management/custom_listing"

exports.teenUpcomingAPIEndPoint = "bx_block_request_management/upcoming_service"
exports.teenReceviedRequestAPIEndPoint = "bx_block_request_management/received"
exports.teenPastAPIEndPoint =  "bx_block_request_management/past_requests"
exports.teenCanceledAPIEndPoint = "bx_block_request_management/cancel_listing"

exports.workAndPrefrenceShowEndPoint = "bx_block_profile/show_custom_form"
exports.workAndPrefrenceSaveEndPoint = "bx_block_profile/update_custom_form"

exports.earningHistoryAPIEndPoint = "bx_block_stripe_integration/earnings_history"
exports.bookingrequestApiEndpoint = "/bx_block_appointment_management/booking_requests"

exports.workPreferencesAndInterestsData = [
  {
    checked: false,
    heading: "Tech Help",
    subHeading: "iPhone, Android Phone, Windows Laptop, Mac Laptop, Wifi Router, TV Remote "
  },
  {
    checked: false,
    heading: "Indoor Help",
    subHeading: "Change lightbulbs, Carry in boxes or groceries, Water and maintain houseplants, Help with meal prep, Laundry and housekeeping."
  },
  {
    checked: false,
    heading: "Outdoor Help",
    subHeading: "Mow and edge lawns, Plant seeds or starters, Spread mulch, Blow or rake debris."
  },
  {
    checked: false,
    heading: "Friendly Visits",
    subHeading: "Make conversation, Play games, Help with hobbies, Discuss shared interests."
  },
  {
    checked: false,
    heading: "Organizing",
    subHeading: "Sort through, organize, box up, label, and arrange items, Help sell or donate items online."
  },
  {
    checked: false,
    heading: "Pet Care",
    subHeading: "Walk dogs, Change litter boxes, Fill pet food bins, Brush or play with furry family members, Clean out fish tanks."
  },
  {
    checked: false,
    heading: "Run Errands (required you own reliable car)",
    subHeading: "Pick up groceries or small household items, Pick up mail or packages, Drop off items for shipping, Take items to charity donation sites."
  }
]

// Customizable Area End

